exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cardapio-index-tsx": () => import("./../../../src/pages/cardapio/index.tsx" /* webpackChunkName: "component---src-pages-cardapio-index-tsx" */),
  "component---src-pages-contato-index-tsx": () => import("./../../../src/pages/contato/index.tsx" /* webpackChunkName: "component---src-pages-contato-index-tsx" */),
  "component---src-pages-franquias-index-tsx": () => import("./../../../src/pages/franquias/index.tsx" /* webpackChunkName: "component---src-pages-franquias-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quem-somos-index-tsx": () => import("./../../../src/pages/quem-somos/index.tsx" /* webpackChunkName: "component---src-pages-quem-somos-index-tsx" */),
  "component---src-pages-trabalhe-conosco-index-tsx": () => import("./../../../src/pages/trabalhe-conosco/index.tsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-index-tsx" */),
  "component---src-templates-blog-show-tsx": () => import("./../../../src/templates/blog/show.tsx" /* webpackChunkName: "component---src-templates-blog-show-tsx" */),
  "component---src-templates-menu-show-tsx": () => import("./../../../src/templates/menu/show.tsx" /* webpackChunkName: "component---src-templates-menu-show-tsx" */)
}

